import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import Posts from '../components/Posts'
import SEO from '../components/SEO'

function Tags(props = {}) {
  const { tag } = props.pageContext
  const { edges, totalCount } = props.data.allMdx
  const posts = edges

  return (
    <Layout>
      <SEO title={`#${tag} | Blog`} />
      <PageHeader
        title="Blog"
        description={`${totalCount} post${
          totalCount > 1 ? 's' : ''
        } tagged with "${tag}".`}
      />
      <Posts posts={posts} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($tag: String) {
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }, draft: { ne: true } } }
    ) {
      totalCount
      edges {
        node {
          excerpt
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            path
            tags
          }
        }
      }
    }
  }
`

export default Tags
